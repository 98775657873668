<template>
    <div class="modal fade show" id="geofenceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-modal="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form id="geofenceForm" class="kt-form" novalidate="novalidate" @submit.prevent="onSubmit">
                    <div class="modal-header">
                        <h5 v-if="isCreation" class="modal-title">{{ $t("geofence_geofenceCreation") }}</h5>
                        <h5 v-else class="modal-title">{{ $t("geofence_geofenceUpdate") }}</h5>
                        <button type="button" @click="cancelGeofence" class="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group validated">
                            <label>{{ $t("geofence_geofenceName") }} *</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                </div>
                                <input id="geofenceNameInput" type="text" class="form-control" :placeholder="$t('geofence_inputInfoGeofenceName')" v-model="geofenceName" />
                            </div>
                            <div v-if="!$v.geofenceName.required" class="invalid-feedback">
                                {{ $t("error_fieldIsRequired") }}
                            </div>
                            <div v-else-if="!$v.geofenceName.minLen" class="invalid-feedback">
                                {{ $t("error_minLengthMsg", [$v.geofenceName.$params.minLen.min]) }}
                            </div>
                            <span class="form-text text-muted">{{ $t("geofence_detailInfoGeofenceName") }}</span>
                        </div>
                        <div class="form-group">
                            <label>{{ $t("geofence_color") }}</label>
                            <div class="input-group">
                                <input id="geofenceColorInput" class="form-control" type="color" v-model="geofenceColor" />
                            </div>
                            <span class="form-text text-muted">{{ $t("geofence_detailInfoGeofenceColor") }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="geofenceModalCancelButton" type="button" class="btn btn-secondary" @click="cancelGeofence" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                        <button v-if="isCreation" id="geofenceModalSubmitButton" type="button" @click="submitGeofence" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_create") }}</button>
                        <button v-else id="geofenceModalSubmitButton" type="button" @click="submitGeofence" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_update") }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            geofenceName: "",
            geofenceColor: "#3388ff",
            isCreation: false
        };
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentGeofence: function(geofence) {
            console.log("Component(geofenceModal)::watch(currentGeofence) - called with : ", geofence);
            if (geofence.state == "FINALIZE_CREATION" || geofence.state == "FINALIZE_EDITION") {
                this.isCreation = geofence.state == "FINALIZE_CREATION" ? true : false;
                this.geofenceName = geofence.name;
                this.geofenceColor = "#" + geofence.color;
                $("#geofenceModal").modal("show");
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentGeofence"])
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        geofenceName: {
            required,
            minLen: minLength(2)
        }
    },
    methods: {
        ...mapActions(["createGeofence", "updateGeofence", "setCurrentGeofence", "getGeofenceById"]),
        submitGeofence() {
            console.log("Component(geofenceModal)::submitGeofence() - called");
            let geofenceData = this.currentGeofence;
            geofenceData.name = this.geofenceName;
            geofenceData.color = this.geofenceColor.substring(1); // remove '#' character
            if (this.isCreation) {
                this.createGeofence(geofenceData);
            } else {
                this.updateGeofence(geofenceData);
            }
            $("#geofenceModal").modal("hide");

            // Reset current geofence in store
            this.setCurrentGeofence({ id: "", state: "" });
        },
        cancelGeofence() {
            console.log("Component(geofenceModal)::cancelGeofence() - called");
            if (this.currentGeofence.state === "FINALIZE_CREATION") {
                this.setCurrentGeofence({ id: "", state: "" });
            } else if (this.currentGeofence.state === "FINALIZE_EDITION") {
                this.setCurrentGeofence({ id: "", state: "CANCEL_EDIT" });
            } else {
                this.setCurrentGeofence({ id: "", state: "" });
            }
        },
        onSubmit() {
            console.log("Component(geofenceModal)::onSubmit() - called");
            if (!this.$v.$invalid) {
                this.submitGeofence();
            }
        }
    }
};
</script>

<style scoped></style>
